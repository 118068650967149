<template>
    <ValidationObserver ref="wizardPage2" id="wizardPage2" name="wizardPage2">
        <div v-if="appData">
            <claim-summary :app-data="appData" :ref-data="refData" :is-read-only="isReadOnly" v-on="$listeners" />

            <claim-policy-summary :app-data="appData" :ref-data="refData" :is-read-only="isReadOnly" v-on="$listeners" />

            <claim-alert :app-data="appData" :is-read-only="isReadOnly" v-on="$listeners" />

            <claim-follow-up :app-data="appData" :is-read-only="isReadOnly" />

            <instructions :instructions-text="instructions" />

            <!-- <pre>{{ JSON.stringify(appData, null, 2 ) }}</pre> -->

            <!-- Insurer Contacts -->
            <claim-contacts title="Insurer Contacts" :contact-type="'Insurer'" :crm-entity="contactsEntity"
                :all-contacts="allInsurerContacts" :app-data="appData" :ref-data="refData" :allow-primary-selection="true"
                :is-read-only="isReadOnly" v-on="$listeners" />

            <!-- Supplier Contacts -->
            <claim-contacts title="Supplier Contacts" :contact-type="'Supplier'" :crm-entity="supplierContactsEntity"
                :all-contacts="allSupplierContacts" :app-data="appData" :ref-data="refData" :allow-primary-selection="true"
                :is-read-only="isReadOnly" v-on="$listeners"
                @primary-contact-changed="updatePrimaryContact('Insurer', $event)" />

            <b-card border-variant="primary" body-class="px-4 py-4" class="mt-10">
                <h4 class="font-weight-bold text-dark">
                    <i class="fas fa-bell fa-md text-primary"></i>
                    <span class="ml-2" style="vertical-align: center">Builder/Insurer Lodgement</span>
                </h4>
                <!-- Notify Claim to Builder -->
                <button type="button" class="btn btn-primary font-weight-bold px-4 py-3 mt-7 mr-3" v-if="!isReadOnly"
                    @click.prevent="sendClaimEmailToBuilder" :disabled="!canSendEmailToBuilder">
                    <i class="fa fa-envelope"></i>
                    Appoint Builder to Claim
                </button>

                <!-- Lodge Claim with Insurer -->
                <button type="button" class="btn btn-primary font-weight-bold px-4 py-3 mt-7" v-if="!isReadOnly"
                    @click.prevent="getClaimFormToInsurerEmailTemplate" :disabled="appData.statusFlags.insurerNotified">
                    <i class="fa fa-envelope"></i>
                    Lodge Claim with Insurer
                </button>

                <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }"
                    name="appData.statusFlags.insurerNotified" v-slot="{ errors }">
                    <div class="row mt-5">
                        <span class="switch switch-sm switch-outline switch-icon switch-primary ml-4">
                            <label>
                                <input type="checkbox" :disabled="isReadOnly || insurerNotified" v-model="insurerNotified"
                                    @change="handleInsurerNotifiedChange" />
                                <span></span>
                            </label>
                        </span>
                        <label class="col-form-label font-size-h5 ml-2">
                            I confirm that I have notified the insurer.
                        </label>
                    </div>
                    <span v-if="errors.length" class="error-message">{{
                        errors[0]
                    }}</span>
                </ValidationProvider>
            </b-card>

            <b-card border-variant="primary" body-class="px-4 py-4" class="mt-10" v-if="insurerNotified">
                <h4 class="font-weight-bold text-dark">
                    <i class="fas fa-reply-all fa-md text-primary"></i>
                    <span class="ml-2" style="vertical-align: center">Lodgement Outcome Details </span>
                </h4>

                <div v-if="appData.statusFlags.insurerNotified" class="mt-10">
                    <div class="form-group row">
                        <label class="col-2 col-form-label font-weight-bold">Lodgement Outcome<span
                                class="text-danger">*</span></label>
                        <div class="col-3">
                            <ValidationProvider rules="required" name="K_InsurerClaimNumber" v-slot="{ errors }">
                                <b-form-select v-model="appData.claim.K_LodgementOutcome"
                                    :disabled="isReadOnly || appData.statusFlags.claimInProgress">
                                    <option :value="null">-- Select ---</option>
                                    <option value="Claim Lodged">Claim Lodged</option>
                                    <option value="Insurer Notification Only">
                                        Insurer Notification Only
                                    </option>
                                    <option value="More Information Required">
                                        More Information Required
                                    </option>
                                </b-form-select>
                                <span v-if="errors.length" class="error-message">{{
                                    errors[0]
                                }}</span>
                            </ValidationProvider>
                        </div>
                    </div>

                </div>

                <div v-if="appData.statusFlags.insurerNotified &&
                        appData.claim.K_LodgementOutcome
                        ">
                    <div v-if="appData.claim.K_LodgementOutcome === 'Insurer Notification Only' ||
                        appData.claim.K_LodgementOutcome === 'More Information Required'
                        ">
                        <div class="form-group row">
                            <label class="col-2 col-form-label font-weight-bold">Enter Details<span
                                    class="text-danger">*</span></label>
                            <div class="col-10">
                                <ValidationProvider rules="required" name="K_LodgementOutcomeInfo" v-slot="{ errors }">
                                    <b-textarea class="form-control" rows="4" placeholder="Enter Details" size="2000"
                                        v-model="appData.claim.K_LodgementOutcomeInfo" trim></b-textarea>
                                    <span v-if="errors.length" class="error-message">{{
                                        errors[0]
                                    }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>

                    <div class="form-group" v-if="!isReadOnly">
                        <button type="button" class="btn btn-primary font-weight-bold px-4 py-3"
                            @click.prevent="saveLodgementStatus" :disabled="savingLodgementStatus ||
                                !isSaveLodgementStatusButtonEnabled ||
                                appData.statusFlags.claimInProgress
                                ">
                            <i class="fa fa-save"></i>
                            Save Lodgement Status
                        </button>
                        <b-spinner class="ml-3 align-middle" variant="primary" v-if="savingLodgementStatus"></b-spinner>
                    </div>

                    <div class="row justify-content-center">
                        <!-- Hidden input to validate if claim has been lodged -->
                        <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }" name="feesUpdated"
                            v-slot="{ errors }">
                            <input type="hidden" v-model="appData.statusFlags.claimLodged" />
                            <span class="text-danger">{{
                                errors[0]
                                ? "Claim must be lodged with insurer before proceeding."
                                : ""
                            }}</span>
                        </ValidationProvider>
                    </div>

                    <div v-if="appData.statusFlags.claimLodged &&
                            appData.claim.K_LodgementOutcome === 'Claim Lodged'
                            ">
                        <button type="button" class="btn btn-primary font-weight-bold px-4 py-3 mt-4" v-if="!isReadOnly"
                            @click.prevent="getClaimValidationEmailTemplate"
                            :disabled="appData.statusFlags.claimInProgress">
                            <i class="fa fa-envelope"></i>
                            Send Claim Validation Email
                        </button>

                        <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }"
                            name="appData.statusFlags.claimInProgress" v-slot="{ errors }">
                            <div class="row mt-10">
                                <span class="switch switch-sm switch-outline switch-icon switch-primary ml-4">
                                    <label>
                                        <input type="checkbox" :disabled="isReadOnly"
                                            v-model="appData.statusFlags.claimInProgress"
                                            @change="setValidationEmailSentStatus()" />
                                        <span></span>
                                    </label>
                                </span>
                                <label class="col-form-label font-size-h5 ml-2">
                                    I confirm that I have sent the claim validation email.
                                </label>
                            </div>
                            <span v-if="errors.length" class="error-message">{{
                                errors[0]
                            }}</span>
                        </ValidationProvider>

                        <div v-if="appData.claim.K_LodgementOutcome === 'Claim Lodged'">
                            <div class="form-group row mt-8">
                                <label class="col-2 col-form-label font-weight-bold">Insurer Claim Number</label>
                                <div class="col-3 d-flex">
                                    <ValidationProvider name="K_InsurerClaimNumber" v-slot="{ errors }">
                                        <input type="text" class="form-control" maxlength="255"
                                            v-model="appData.claim.K_InsurerClaimNumber" :disabled="isReadOnly || appData.statusFlags.claimReportSent
                                                " />
                                        <span v-if="errors.length" class="error-message">{{
                                            errors[0]
                                        }}</span>
                                    </ValidationProvider>

                                </div>
                                <button type="button" class="btn btn-primary font-weight-bold px-4 py-3"
                                    @click="saveInsurerClaimNumber" :disabled="isReadOnly ||
                                            appData.statusFlags.claimReportSent ||
                                            savingLodgementStatus
                                            ">
                                    <i class="fa fa-save"></i>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </b-card>
        </div>
    </ValidationObserver>
</template>

<style lang="scss">
span.due-date {
    font-size: 15px;
}

.edit-form {
    top: 15px;
    margin-right: 25px;
    cursor: pointer;
}

.main-edit-pen {
    opacity: 0;
    cursor: pointer;
}

.input-main-check:hover .main-edit-pen {
    opacity: 1;
    cursor: pointer;
}
</style>

<script>
import _ from "lodash";
import ClaimSummary from "../components/ClaimSummary";
import ClaimPolicySummary from "../components/ClaimPolicySummary";
import ClaimAlert from "../components/ClaimAlert";
import ClaimFollowUp from "../components/ClaimFollowUp.vue";
import Instructions from "../../../common/Instructions";
import ClaimContacts from "../components/ClaimContacts.vue";

export default {
    name: "LodgementPage",

    components: {
        ClaimSummary,
        ClaimPolicySummary,
        ClaimFollowUp,
        ClaimAlert,
        Instructions,
        ClaimContacts,
    },

    props: {
        appData: Object,
        refData: Object,
        isReadOnly: Boolean,
        hideButtons: Boolean,
    },

    data() {
        return {
            isEdit: false,
            errorAlert: null,
            savingLodgementStatus: false,
            insurerNotified: false,
        };
    },

    mounted() {
        this.initializeClaimFormLinks();
    },

    methods: {
        updatePrimaryContact(contactType, primaryContactId) {
            if (contactType === 'Insurer') {
                let claimContacts = _.get(this.appData, "claim.K_ClaimContacts", []);
                claimContacts.forEach(contact => {
                    if (contact.K_ContactType === "Insurer" || contact.K_ContactType === "Underwriter") {
                        contact.K_Primary = contact.id === primaryContactId;
                    }
                });
                this.$forceUpdate();
            }
        },

        initializeClaimFormLinks() {
            // Set both links immediately
            this.$store.commit('setClaimFormViewLink', this.appData.claim.K_ClaimFormViewLink);
            this.$store.commit('setClaimFormInternalLink', this.appData.claim.K_ClaimFormInternalLink);

            // Set insurer notified status
            const insurerNotified = this.appData.statusFlags.insurerNotified;
            this.$store.commit('setInsurerNotified', insurerNotified);

            // Initialize local data
            this.insurerNotified = insurerNotified;

            // If insurer is already notified, ensure we're using the view link
            if (insurerNotified) {
                this.$store.commit('setClaimFormInternalLink', null);
            }
        },

        sendClaimEmailToBuilder: function () {
            this.$commonDataService
                .getEmailTemplate("CLAIM_FORM_TO_BUILDER")
                .then(({ data }) => {
                    let emailTemplate = data.result;
                    const url = window.URL.createObjectURL(new Blob([emailTemplate]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        `cib-email-${new Date().getTime()}.eml`
                    );
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    this.$emit("handleError", error);
                });
        },

        handleInsurerNotifiedChange() {
            this.updateInsurerNotifiedStatus();
            if (this.insurerNotified) {
                this.disableClaimFormEditing();
            }
        },

        updateInsurerNotifiedStatus() {
            this.$store.commit('setInsurerNotified', this.insurerNotified);
            this.appData.statusFlags.insurerNotified = this.insurerNotified;
        },

        getClaimFormToInsurerEmailTemplate: function () {
            // Check if primary-insurer-contact has been selected
            if (!this.insurerPrimaryContactSelected) {
                this.$emit(
                    "handleError",
                    "UI_ERROR: Please select a primary insurer contact."
                );
                return;
            }

            this.$commonDataService
                .getEmailTemplate("CLAIM_FORM_TO_INSURER")
                .then(({ data }) => {
                    let emailTemplate = data.result;
                    const url = window.URL.createObjectURL(new Blob([emailTemplate]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        `cib-email-${new Date().getTime()}.eml`
                    );
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    this.$emit("handleError", error);
                });
        },

        getClaimValidationEmailTemplate: function () {
            this.$commonDataService
                .getEmailTemplate("CLAIM_ACKNOWLEDGEMENT")
                .then(({ data }) => {
                    let emailTemplate = data.result;
                    const url = window.URL.createObjectURL(new Blob([emailTemplate]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        `cib-email-${new Date().getTime()}.eml`
                    );
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    this.$emit("handleError", error);
                });
        },

        saveInsurerClaimNumber: function () {
            if (this.appData && this.appData.claim.K_InsurerClaimNumber) {
                this.saveLodgementStatus();
            } else {
                this.$emit(
                    "handleError",
                    "UI_ERROR: Please enter an Insurer Claim Number."
                );
            }
        },

        disableClaimFormEditing() {
            if (!this.appData.claim.K_ClaimFormInternalLink) {
                console.log('No claim form internal link exists. Skipping disable process.');
                return;
            }

            this.$claimDataService.disableClaimFormEditing(this.appData.claim.K_ClaimFormInternalLink)
                .then(() => {
                    console.log('Claim form editing has been disabled');
                    // Remove the internal link from appData
                    this.appData.claim.K_ClaimFormInternalLink = null;

                    // Update the store
                    this.$store.commit('setClaimFormInternalLink', null);

                    // Ensure the view link is set
                    this.$store.commit('setClaimFormViewLink', this.appData.claim.K_ClaimFormViewLink);

                    // Emit event to parent to save the updated data
                    this.$emit('saveWizardAppData', true);

                    // Force update to ensure the view is refreshed
                    this.$forceUpdate();
                })
                .catch((error) => {
                    this.$emit("handleError", error);
                    console.error('Failed to disable claim form editing:', error);
                });
        },

        saveLodgementStatus: function () {
            if (this.appData) {
                this.savingLodgementStatus = true;

                let lodgementData = {
                    K_LodgementOutcome: this.appData.claim.K_LodgementOutcome,
                    K_InsurerClaimNumber: this.appData.claim.K_InsurerClaimNumber,
                    K_LodgementOutcomeInfo: this.appData.claim.K_LodgementOutcomeInfo,
                };
                this.$claimDataService
                    .saveLodgementStatus(lodgementData)
                    .then(({ data }) => {
                        let result = _.get(data, "result");
                        if (result && result.hasOwnProperty("K_Status")) {
                            this.appData.statusFlags = result.statusFlags;
                            this.appData.claim.K_Status = result.K_Status;
                            this.appData.claim.K_NextStep = result.K_NextStep;
                            this.$emit("updateGlobalClaimStatus");
                        }
                        this.savingLodgementStatus = false;
                    })
                    .catch((error) => {
                        this.savingLodgementStatus = false;
                        this.$emit("handleError", error);
                    });
            }
        },

        setValidationEmailSentStatus: function () {
            if (!this.appData.statusFlags.claimInProgress) {
                return;
            }
            let updatedData = { statusFlag: "claimInProgress" };
            this.$claimDataService
                .updateClaimData(updatedData)
                .then(({ data }) => {
                    let result = _.get(data, "result");
                    if (result && result.hasOwnProperty("K_Status")) {
                        this.appData.statusFlags = result.statusFlags;
                        this.appData.claim.K_Status = result.K_Status;
                        this.appData.claim.K_NextStep = result.K_NextStep;
                        this.$emit("updateGlobalClaimStatus");
                    }
                })
                .catch((error) => {
                    this.$emit("handleError", error);
                });
        },
    },

    computed: {
        canSendEmailToBuilder: function () {
            return this.appData.claim.K_ClaimContacts.some(
                (contact) => contact.K_ContactType === "Supplier" && contact.K_Primary
            );
        },

        allSupplierContacts() {
            const policyContacts = this.appData.policy.K_Supplier?.contacts || [];
            const claimContacts =
                this.appData.claim.K_ClaimContacts.filter(
                    (c) => c.K_ContactType === "Supplier"
                ) || [];
            const allContacts = this.refData.allContacts || [];
            const supplierContacts = allContacts.filter(
                (c) => c.K_ContactType === "Supplier"
            ); // Add this line
            const combinedContacts = [
                ...policyContacts,
                ...claimContacts,
                ...supplierContacts,
            ];
            return _.uniqBy(combinedContacts, "id");
        },

        supplierContactsEntity() {
            return {
                type: "Supplier",
                id: this.appData.policy.K_Supplier?.id,
                name: this.appData.policy.K_Supplier?.name,
                link: this.supplierCrmLink,
            };
        },

        supplierCrmLink() {
            let supplier = _.get(this.appData, "policy.K_Supplier");
            if (supplier) {
                return `https://crm.zoho.com.au/crm/tab/${supplier.K_CustomModuleName}/${supplier.id}`;
            }
            return null;
        },

        allInsurerContacts() {
            const policyContacts = this.appData.policy.K_Insurer.contacts || [];
            const claimContacts =
                this.appData.claim.K_ClaimContacts.filter(
                    (c) => c.K_ContactType === "Insurer"
                ) || [];
            return _.uniqBy([...policyContacts, ...claimContacts], "id");
        },

        contactsEntity: function () {
            return {
                type: "Insurer",
                id: this.appData.policy.K_Insurer.id,
                name: this.appData.policy.K_Insurer.name,
                link: this.insurerCrmLink,
            };
        },

        instructions: function () {
            return (
                "Please review the Insurer and Supplier Contact details below to ensure they are correct.<br/><br/>" +
                "To associate/disassociate an existing Insurer or Supplier Contact, click on 'Select Contacts' and select/deselect the appropriate contact. " +
                "To add a new Insurer or Supplier Contact, click the '+' button. If the Contact details are incorrect, these will need to be updated in CRM and the wizard refreshed to display the updated details. " +
                "Click on the Insurer or Supplier link below, click on the Contacts related list (left panel), edit and save the appropriate Contact record then refresh the wizard browser tab.<br/><br/>" +
                "Once the Contact details are correct, you can appoint a builder to the claim if necessary. Then lodge the claim with the insurer via email, and specify the Lodgement Outcome. If appropriate, send the Claim Verification email to the client/strata manager."
            );
        },

        insurerCrmLink: function () {
            let insurer = _.get(this.appData, "policy.K_Insurer");
            if (insurer) {
                return `https://crm.zoho.com.au/crm/tab/${insurer.K_CustomModuleName}/${insurer.id}`;
            }
            return null;
        },

        isSaveLodgementStatusButtonEnabled: function () {
            if (this.appData) {
                let lodgementOutcome = _.get(this.appData, "claim.K_LodgementOutcome");
                if (lodgementOutcome === "Claim Lodged") {
                    return !this.appData.claim.K_InsurerClaimNumber;
                }
                if (
                    lodgementOutcome === "Insurer Notification Only" ||
                    lodgementOutcome === "More Information Required"
                ) {
                    return !!this.appData.claim.K_LodgementOutcomeInfo;
                }
            }
            return false;
        },

        insurerPrimaryContactSelected: function () {
            let claimContacts = _.get(this.appData, "claim.K_ClaimContacts", []);
            return claimContacts.some(contact =>
                (contact.K_ContactType === "Insurer" || contact.K_ContactType === "Underwriter") && contact.K_Primary === true
            );
        },
    },

    watch: {
        appData: {
            handler(newData, oldData) {
            },
            deep: true,
        },

        'appData.statusFlags.claimLodged': function (newValue) {
            this.$store.commit("setClaimLodged", newValue);
        },

        'appData.statusFlags.insurerNotified': {
            handler(_) {
                this.updateInsurerNotifiedStatus();
            },
        },
    },
};
</script>
