<template>
  <div>
    <ValidationObserver ref="assessmentDetails" v-slot="{ validate }">
      <b-card border-variant="primary" body-class="px-4 py-4" class="mt-10">
        <h4 class="font-weight-bold text-dark" v-b-toggle="'assessment-detail-panel'">
          <i class="fa fa-file-alt fa-md text-primary"></i>
          <span class="ml-2" style="vertical-align: center">Assessment / Builder Details</span>

          <i v-if="!isExpanded" class="fas fa-angle-double-down fa-md float-right" title="Click to Expand"></i>
          <i v-if="isExpanded" class="fas fa-angle-double-up fa-md float-right" title="Click to Collapse"></i>
        </h4>

        <b-collapse id="assessment-detail-panel" v-model="isExpanded" v-if="appData">
          <div v-if="appData">
            <!-- ROW 1 -->
            <div class="form-group row">
              <label class="col-3 col-form-label text-right">Is an Assessment required?</label>
              <div class="col-3">
                <ValidationProvider rules="required" name="K_AssessmentRequired" v-slot="{ errors }">
                  <b-form-select v-model="dataBeingEdited.K_AssessmentRequired" :disabled="isReadOnly || !isEditMode">
                    <option :value="null">-- Select ---</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </b-form-select>
                  <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <div v-if="dataBeingEdited.K_AssessmentRequired === 'Yes'">
              <!-- ROW 2 -->
              <div class="form-group row">
                <label class="col-3 col-form-label text-right">Has Assessor/Builder been appointed?</label>
                <div class="col-3">
                  <ValidationProvider rules="required" name="K_AssessorAppointed" v-slot="{ errors }">
                    <b-form-select v-model="dataBeingEdited.K_AssessorAppointed" :disabled="isReadOnly || !isEditMode">
                      <option :value="null">-- Select ---</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </b-form-select>
                    <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <label v-if="dataBeingEdited.K_AssessorAppointed === 'Yes'" class="col-3 col-form-label text-right">Date
                  Assessor Appointed</label>
                <div v-if="dataBeingEdited.K_AssessorAppointed === 'Yes'" class="col-3">
                  <ValidationProvider rules="required" name="K_DateAssessorAppointed" v-slot="{ errors }">
                    <b-form-datepicker class="form-control" :locale="'en-AU'"
                      v-model="dataBeingEdited.K_DateAssessorAppointed" placeholder="DD/MM/YYYY" :date-format-options="{
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }" :disabled="isReadOnly || !isEditMode">
                    </b-form-datepicker>
                    <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>

              <!-- ROW 3 -->
              <div class="form-group row" v-if="dataBeingEdited.K_AssessorAppointed === 'Yes'">
                <label class="col-3 col-form-label text-right">Name of Assessor Company</label>
                <div class="col-3">
                  <input type="text" class="form-control" maxlength="255"
                    v-model="dataBeingEdited.K_AssessmentCompanyName" :disabled="isReadOnly || !isEditMode" />
                </div>

                <label class="col-3 col-form-label text-right">Assessor/Builder reference number</label>
                <div class="col-3">
                  <input type="text" class="form-control" maxlength="255"
                    v-model="dataBeingEdited.K_AssessorReferenceNumber" :disabled="isReadOnly || !isEditMode" />
                </div>
              </div>

              <div v-if="dataBeingEdited.K_AssessorAppointed === 'Yes'">
                <!-- ROW 4 -->
                <div class="form-group row">
                  <label class="col-3 col-form-label text-right">Name of the Appointed Builder</label>
                  <div class="col-3">
                    <ValidationProvider rules="max:255" name="K_AppointedBuilderName" v-slot="{ errors }">
                      <input type="text" class="form-control" maxlength="255"
                        v-model="dataBeingEdited.K_AppointedBuilderName" :disabled="isReadOnly || !isEditMode" />
                      <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>

                <!-- ROW 5 -->
                <div class="form-group row">
                  <label class="col-3 col-form-label text-right">Has Assessment Report been received?</label>
                  <div class="col-3">
                    <ValidationProvider rules="required" name="K_AssessmentReportReceived" v-slot="{ errors }">
                      <b-form-select v-model="dataBeingEdited.K_AssessmentReportReceived"
                        :disabled="isReadOnly || !isEditMode">
                        <option :value="null">-- Select ---</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </b-form-select>
                      <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <label v-if="dataBeingEdited.K_AssessmentReportReceived === 'Yes'"
                    class="col-3 col-form-label text-right">Date Report Received</label>
                  <div v-if="dataBeingEdited.K_AssessmentReportReceived === 'Yes'" class="col-3">
                    <ValidationProvider rules="required" name="K_AssessmentReportDate" v-slot="{ errors }">
                      <b-form-datepicker class="form-control" :locale="'en-AU'"
                        v-model="dataBeingEdited.K_AssessmentReportDate" placeholder="DD/MM/YYYY" :date-format-options="{
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        }" :disabled="isReadOnly || !isEditMode">
                      </b-form-datepicker>
                      <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>

                <!-- ROW 6 -->
                <div class="form-group row" v-if="dataBeingEdited.K_AssessmentReportReceived === 'Yes'">
                  <label class="col-3 col-form-label text-right">Has Assessment been approved by the insurer?</label>
                  <div class="col-3">
                    <ValidationProvider rules="" name="K_AssessmentApprovedByInsurer" v-slot="{ errors }">
                      <b-form-select v-model="dataBeingEdited.K_AssessmentApprovedByInsurer"
                        :disabled="isReadOnly || !isEditMode">
                        <option :value="null">-- Select ---</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </b-form-select>
                      <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <label v-if="
                    dataBeingEdited.K_AssessmentApprovedByInsurer === 'Yes'
                  " class="col-3 col-form-label text-right">Approval Date</label>
                  <div v-if="
                    dataBeingEdited.K_AssessmentApprovedByInsurer === 'Yes'
                  " class="col-3">
                    <ValidationProvider rules="required" name="K_AssessmentApprovalDate" v-slot="{ errors }">
                      <b-form-datepicker class="form-control" :locale="'en-AU'"
                        v-model="dataBeingEdited.K_AssessmentApprovalDate" placeholder="DD/MM/YYYY"
                        :date-format-options="{
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        }" :disabled="isReadOnly || !isEditMode">
                      </b-form-datepicker>
                      <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <button class="btn btn-primary col-2" v-if="!isReadOnly && !isEditMode" @click.prevent="editData"
                :disabled="savingData">
                Edit Assessment
              </button>
              <button class="btn btn-primary col-2" v-if="!isReadOnly && isEditMode" @click.prevent="saveData"
                :disabled="savingData">
                Save Assessment
              </button>
              <b-spinner class="ml-3 align-middle" variant="primary" v-if="savingData"></b-spinner>
              <button class="btn btn-secondary ml-3" v-if="!isReadOnly && isEditMode" @click.prevent="cancelEditMode"
                :disabled="savingData">
                Cancel
              </button>
            </div>
          </div>
        </b-collapse>
      </b-card>
    </ValidationObserver>
  </div>
</template>

<style lang="scss"></style>

<script>
import _ from "lodash";

export default {
  name: "AssessmentDetails",

  props: {
    appData: Object,
    isReadOnly: Boolean,
  },

  mounted() {
    if (this.appData && this.appData.claim) {
      this.populateDataBeingEdited();
    }
  },

  data() {
    return {
      isExpanded: true,
      isEditMode: false,
      dataBeingEdited: {},
      savingData: false,
    };
  },

  methods: {
    populateDataBeingEdited: function () {
      this.dataBeingEdited = {
        K_AssessmentRequired:          this.appData.claim.K_AssessmentRequired,
        K_AssessorAppointed:           this.appData.claim.K_AssessorAppointed,
        K_DateAssessorAppointed:       this.appData.claim.K_DateAssessorAppointed,
        K_AssessmentCompanyName:       this.appData.claim.K_AssessmentCompanyName,
        K_AssessorReferenceNumber:     this.appData.claim.K_AssessorReferenceNumber,
        K_AssessmentReportReceived:    this.appData.claim.K_AssessmentReportReceived,
        K_AssessmentReportDate:        this.appData.claim.K_AssessmentReportDate,
        K_AssessmentApprovedByInsurer: this.appData.claim.K_AssessmentApprovedByInsurer,
        K_AssessmentApprovalDate:      this.appData.claim.K_AssessmentApprovalDate,
        K_AppointedBuilderName:        this.appData.claim.K_AppointedBuilderName,
      };
    },

    editData: function () {
      this.populateDataBeingEdited();
      this.isEditMode = true;
    },

    saveData: function () {
      this.$refs.assessmentDetails.validate().then((isValid) => {
        if (isValid) {
          this.savingData = true;
          this.$claimDataService
            .updateClaimData(this.dataBeingEdited)
            .then(({ data }) => {
              for (let key of Object.keys(this.dataBeingEdited)) {
                this.appData.claim[key] = this.dataBeingEdited[key];
              }
              this.populateDataBeingEdited();

              let result = _.get(data, "result");
              if (result && result.hasOwnProperty("K_Status")) {
                this.appData.statusFlags      = result.statusFlags;
                this.appData.claim.K_Status   = result.K_Status;
                this.appData.claim.K_NextStep = result.K_NextStep;
                this.$emit("updateGlobalClaimStatus");
              }

              this.isEditMode = false;
              this.savingData = false;
            })
            .catch((error) => {
              this.savingData = false;
              this.$emit("handleError", error);
            });
        }
      });
    },

    cancelEditMode: function () {
      this.populateDataBeingEdited();
      this.isEditMode = false;
    },
  },
};
</script>
