<template>
  <ValidationObserver ref="wizardPage3" id="wizardPage3" name="wizardPage3">
    <div>
      <div v-if="appData">
        <claim-summary
          :app-data="appData"
          :ref-data="refData"
          :is-read-only="isReadOnly"
          v-on="$listeners"
        />

        <claim-policy-summary
          :app-data="appData"
          :ref-data="refData"
          :is-read-only="isReadOnly"
          v-on="$listeners"
        />

        <claim-alert
          :app-data="appData"
          :is-read-only="isReadOnly"
          v-on="$listeners"
        />

        <claim-follow-up :app-data="appData" :is-read-only="isReadOnly" />

        <instructions :instructions-text="instructions" />

        <assessment-details
          :app-data="appData"
          :is-read-only="isReadOnly"
          v-on="$listeners"
        />

        <claim-contacts
          title="Claim Contacts"
          :all-contacts="refData.allContacts"
          :app-data="appData"
          :ref-data="refData"
          :is-read-only="isReadOnly"
          :hide-primary-column="true"
          v-on="$listeners"
          :filter-contacts="false"
        />

        <claim-damages :app-data="appData" :is-read-only="isReadOnly" />

        <claim-settlements :app-data="appData" :is-read-only="isReadOnly" @update:claimSettlements="updateClaimSettlements" />
      </div>
    </div>
  </ValidationObserver>
</template>

<style lang="scss"></style>

<script>
import ClaimSummary from "../components/ClaimSummary";
import ClaimPolicySummary from "../components/ClaimPolicySummary";
import ClaimAlert from "../components/ClaimAlert";
import ClaimFollowUp from "../components/ClaimFollowUp.vue";
import Instructions from "../../../common/Instructions";
import AssessmentDetails from "./AssessmentDetails.vue";
import ClaimContacts from "../components/ClaimContacts.vue";
import ClaimDamages from "../components/ClaimDamages.vue";
import ClaimSettlements from "../components/ClaimSettlements.vue";

export default {
  name: "ClaimMgmtPage",

  components: {
    ClaimSummary,
    ClaimPolicySummary,
    ClaimAlert,
    ClaimDamages,
    ClaimFollowUp,
    ClaimSettlements,
    Instructions,
    ClaimContacts,
    AssessmentDetails,
  },

  props: {
    appData: Object,
    refData: Object,
    isReadOnly: Boolean,
  },

  methods: {
    updateClaimSettlements(newSettlements) {
        this.$set(this.appData, 'claimSettlements', newSettlements);
        this.$emit("updateWizardAppDataString");
      }
    },

  computed: {
    instructions: function () {
      return (
        "Edit the Assessment Details below as appropriate. You can also add/update various Claim Damages and Claim " +
        "Settlements as required in the corresponding sections below."
      );
    },
  },
};
</script>
